.link{
    color:black;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-left: 12px;
}

.item{
    padding: 16px;
}

.mt3{
    margin-top: 24px;
}

.text{
    font-family: 'Avenir', sans-serif !important;
}



.image {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px;
    height: 120px;
    margin:-60px 0 0 -60px;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
  }
  @-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
  }
  @-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
  }
  @keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
  }